import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Prism from "prismjs"
import dedent from "dedent"

export default function Code({ code, language }) {
  const pre = useRef()
  useEffect(() => Prism.highlightAllUnder(pre.current), [code, language])
  return (
    <div>
      <pre ref={pre}>
        <code className={`!overflow-hidden language-${language}`}>
          {dedent(code)}
        </code>
      </pre>
    </div>
  )
}
Code.propTypes = {
  code: PropTypes.string,
  language: PropTypes.string,
}
