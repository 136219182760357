import React from "react"
import { Link } from "gatsby"
import { ArrowRightIcon } from "@primer/octicons-react"
import "mono-icons/iconfont/icons.css"

import SEO from "components/seo"
import CodeComparison from "components/code-comparison"
import Button from "components/button-2"

import CLI from "images/sourcery_review_screenshot.png"
import ExtractMethod from "images/extract_method.gif"
import VSCodeRefactoring from "images/vscode-refactoring.png"

const SourceryPro = () => {
  /* const [isAfterCode, setAfterCode] = useState(false) */
  return (
    <div>
      <SEO
        title="Instantly improve your code with Sourcery Pro"
        description="Sourcery makes your code better everywhere you work."
      />

      {/* header start */}
      <div className="bg-black-500 py-20 lg:pt-32 border-solid border-b border-[#000] bg-hero">
        <div className="flex items-center justify-center mx-auto overflow-x-hidden max-w-screen-2xl pt-8">
          <div className="items-start mx-5 grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 lg:mr-0 gap-8 lg:gap-0 lg:px-16">
            <div className="flex flex-col justify-start items-start lg:items-start col-span-1 space-y-7 lg:mt-0">
              <div className="px-6 py-4 rounded-full border-2 border-orange-200">
                <p className="text-orange-200 uppercase font-mono font-bold text-xs leading-[0.875rem]">
                  sourcery for pros
                </p>
              </div>
              <h1 className="text-3xl text-left text-white 2xl:text-6xl md:text-5xl ">
                Develop faster, write
                <br />
                cleaner code
              </h1>
              <p className="text-base lg:text-xl text-black-200 text-left lg:max-w-md">
                Powerful suggestions and whole-project analysis clean your code
                at scale so you can focus more time on building great products.
              </p>
              <Link to="/signup/?product=prod_pro">
                <Button
                  icon={<ArrowRightIcon size={16} />}
                  bgColor="bg-orange-200"
                  textColor="text-black-500"
                  iconColor="text-black-500"
                  animateIcon
                >
                  Get Sourcery
                </Button>
              </Link>
            </div>
            <div className="mx-auto w-full col-span-1 max-w-xl">
              <div className="flex flex-col justify-center items-center w-full">
                <img
                  src={VSCodeRefactoring}
                  className="z-10"
                  alt="VS Code refactoring"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header end */}

      <div className="bg-black-500 lg:pb-8">
        <div className="max-w-screen-2xl mx-auto relative z-10">
          <div className="bg-white pt-16 lg:py-[8rem] lg:mx-[2.5rem] rounded-t-2xl relative">
            {/* power through repetitive tasks */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col">
                <div className="flex items-center mb-2">
                  <div className="mr-4 mt-2">
                    <i className="mi-repeat text-pink-200 " />
                  </div>
                  <div className="ml-2">
                    <p className="font-mono font-bold text-black-300 text-sm">
                      for <span className="text-pink-200">task</span> in{" "}
                      <span className="text-pink-200">tasks</span>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500">
                    Power through repetitive tasks
                  </p>
                  <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6">
                    Catch and fix quality issues before they enter your code to
                    have fewer bugs, greater velocity, and happier teams.
                  </p>
                </div>
                <Link to="/signup/?product=prod_pro">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-pink-100"
                    textColor="text-black-500"
                    iconColor="text-pink-200"
                    animateIcon
                  >
                    Get Sourcery
                  </Button>
                </Link>
              </div>
              <CodeComparison
                options={[
                  {
                    name: "sourcery.yaml",
                    code: `
                        rules:
                        - id: filter-lambda-to-list-comprehension
                          pattern: |
                              list(filter(lambda \${arg}: \${expr}, \${items}))
                          replacement: |
                              [\${arg} for \${arg} in \${items} if \${expr}]
                          description: |
                              Use list comprehensions instead of filter and lambda
                    `,
                    language: "yaml",
                  },
                  {
                    name: "Before",
                    code: `
                        def show_awesome_spells(spellbook):
                            sourcery_spells = list(
                                filter(lambda spell: spell.is_awesome, spellbook)
                            )
                            print(sourcery_spells)
                    `,
                    language: "python",
                  },
                  {
                    name: "After",
                    code: `
                        def show_awesome_spells(spellbook):
                            sourcery_spells = [
                                spell for spell in spellbook if spell.is_awesome
                            ]
                            print(sourcery_spells)
                    `,
                    language: "python",
                  },
                ]}
              />
            </div>
            {/* end power through repetitive tasks */}
            {/* start powerful  feedback */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 lg:py-9 items-center">
              <div className="flex flex-col justify-start md:order-2 md:ml-8">
                <div className="flex mb-4 items-center">
                  <div className="mr-4 mt-3">
                    <i className="mi-eye text-orange-200" />
                  </div>
                  <div className="ml-2">
                    <p className="font-mono font-bold text-black-300 text-sm">
                      while is_Learning:
                      <span className="text-orange-200">
                        {" knowledge.append(information)"}
                      </span>
                    </p>
                  </div>
                </div>
                <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500">
                  Powerful feedback
                </p>
                <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6">
                  Sourcery Pro gives you powerful reviews and suggestions about
                  how to improve your code, like extracting repeated code into
                  its own method.
                </p>
                <Link to="/signup/?product=prod_pro">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-orange-100"
                    textColor="text-black-500"
                    iconColor="text-orange-200"
                    animateIcon
                  >
                    Get Sourcery
                  </Button>
                </Link>
              </div>
              <div className="flex flex-col justify-center items-center md:order-1">
                <img src={ExtractMethod} className="z-10" />
              </div>
            </div>
            {/* end powerful  feedback  */}
            {/* start speed up code reviews */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 lg:pt-9 items-center">
              <div className="flex flex-col">
                <div className="flex mb-4 items-center">
                  <div className="mr-4 mt-3">
                    <i className="mi-clock text-purple-200" />
                  </div>
                  <div>
                    <p className="ml-2 font-mono font-bold text-black-300 text-sm">
                      git commit -m{" "}
                      <span className="text-purple-200">“lgtm”</span>
                    </p>
                  </div>
                </div>
                <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500">
                  Speed up code reviews
                </p>
                <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6">
                  Let Sourcery handle the repetitive stuff you need to deal with
                  in code reviews - style issues, coding standards, best
                  practices, then you can focus on the important side of your
                  work.
                </p>
                <Link to="/signup/?product=prod_pro">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-purple-100"
                    textColor="text-black-500"
                    iconColor="text-purple-200"
                    animateIcon
                  >
                    Get Sourcery
                  </Button>
                </Link>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={CLI} className="z-10 rounded-md" />
              </div>
            </div>
            {/* end speed up code reviews */}
          </div>
          {/* pricing section */}
          {/*
          <div className="bg-black-100 py-[8rem] lg:mx-[2.5rem] rounded-b-2xl">
            <div className="flex flex-col items-center justify-center">
              <div className="rotate-90 text-black-300 mb-2">
                <TagIcon size={20} />
              </div>
              <p className="font-mono font-bold text-base text-black-300 mb-2">
                match typeOfUser:
              </p>
              <p className="text-3xl mb-4 font-bold">
                Simple, flexible pricing
              </p>
              <div className="mt-16 mb-4 flex gap-4 lg:gap-4 px-6 flex-wrap lg:flex-nowrap justify-center">
                <ProductCard
                  forText="For open source projects"
                  heading="Open Source"
                  subHeading="Feedback on open source code anytime, anywhere"
                  link="/signup/?product=prod_opensource"
                  variant="gray"
                >
                  <p className="font-bold text-3xl">Free</p>
                </ProductCard>

                <ProductCard
                  forText="For all projects"
                  heading="Pro"
                  subHeading="Improve any project with the full power of Sourcery"
                  link="/signup/?product=prod_pro"
                  variant="orange"
                >
                  <p className="text-black-300 text-3xl">
                    <span className="font-bold text-black-500">$10</span> per
                    month
                  </p>
                </ProductCard>

                <ProductCard
                  forText="For teams"
                  heading="Team"
                  subHeading="Streamlined code reviews and consistent code quality"
                  link="/signup/?product=prod_team"
                  variant="purple"
                >
                  <p className="text-black-300 text-3xl">
                    <span className="font-bold text-black-500">$30</span> per
                    month
                  </p>
                </ProductCard>
              </div>
            </div>
          </div>
                 */}
        </div>
      </div>
    </div>
  )
}

export default SourceryPro
