import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Code from "components/code"

export default function CodeComparison({ options }) {
  const [selected, setSelected] = useState(0)

  // Reset selected if options change
  useEffect(() => setSelected(0), [options])

  // Rotate through options every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelected((selected + 1) % options.length)
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
  }, [options, selected])

  // If options change use a valid selected option
  const selectedOption = options[selected < options.length ? selected : 0]

  return (
    <div className="w-full rounded-b-md overflow-x-none p-4">
      <div className="flex items-center justify-center">
        <div className="flex items-center bg-black-300 rounded-full font-mono text-xs text-white mt-4">
          {options.map((option, index) => (
            <button
              key={index}
              className={`uppercase px-5 rounded-full font-bold ${
                selected == index && "bg-orange-200 text-black"
              }`}
              onClick={() => setSelected(index)}
            >
              {option.name}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <Code code={selectedOption.code} language={selectedOption.language} />
      </div>
    </div>
  )
}
CodeComparison.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      language: PropTypes.string,
    })
  ).isRequired,
}
